<template>
  <div class="dropdown">
    <button
      class="btn dropdown-toggle"
      type="button"
      id="languageDropdown"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img class="flag-icon" :src="selectedLanguage.flag" />
      <!-- {{ selectedLanguage.name }} -->
    </button>
    <div class="dropdown-menu" aria-labelledby="languageDropdown">
      <a class="dropdown-item" @click="changeLanguage('Ελληνικά')"
        ><img :src="flags.greek" /> {{ $t("menu_greek") }}</a
      >
      <a class="dropdown-item" @click="changeLanguage('English')"
        ><img :src="flags.english" /> {{ $t("menu_english") }}</a
      >
    </div>
  </div>
</template>

<script>
import grFlag from "@/assets/icons/flags/greek.png";
import enFlag from "@/assets/icons/flags/eng.png";
export default {
  created() {
    // Access the $i18n instance to get the current language
    const currentLanguage = this.$i18n.locale;
    switch (currentLanguage) {
      case "gr":
        this.selectedLanguage = {
          name: this.$t("menu_greek"),
          flag: grFlag,
        };
        break;
      case "en":
        this.selectedLanguage = {
          name: this.$t("menu_english"),
          flag: enFlag,
        };
        break;
      default:
        this.selectedLanguage = {
          name: this.$t("menu_greek"),
          flag: grFlag,
        };
    }
  },
  data() {
    return {
      showLanguageDropdown: false,
      selectedLanguage: null,
      flags: {
        greek: grFlag,
        english: enFlag,
      },
    };
  },
  methods: {
    toggleLanguageDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    changeLanguage(language) {
      // Εδώ μπορείτε να βρείτε το αντίστοιχο εικονίδιο;
      switch (language) {
        case "Ελληνικά":
          this.$i18n.locale = "gr";
          this.selectedLanguage.flag = grFlag;
          break;
        case "English":
          this.$i18n.locale = "en";
          this.selectedLanguage.flag = enFlag;
          break;
      }
      const languageDict = {
        Ελληνικά: this.$t("menu_greek"),
        English: this.$t("menu_english"),
      };
      this.selectedLanguage.name = languageDict[language];

      this.showLanguageDropdown = false;
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/language-dropdown.css";
.dropdown-toggle::after {
  display: none;
}
</style>
