// i18n.js
import { createI18n } from "vue-i18n";
import en from "./locales/en";
import gr from "./locales/gr";

const i18n = createI18n({
  locale: "en",
  messages: {
    en,
    gr,
    // Add more locales as needed
  },
});

export default i18n;
