export default {
  menu_greek: "Ελληνικά",
  menu_english: "Αγγλικά",
  //Home
  menu_Home: "Αρχική",
  //services
  menu_services: "Υπηρεσίες",
  submenu_title_Development: "Ανάπτυξη",
  submenu_CustomDesktop: "Προσαρμοσμένες Εφαρμογές Desktop",
  submenu_WebApplications: "Διαδικτυακές Εφαρμογές",
  submenu_MobileApplications: "Κινητές Εφαρμογές",
  submenu_ECommerce: "Λύσεις E-Commerce",
  submenu_ApiDevelopment: "Ανάπτυξη API",
  submenu_MachineLearning: "Λύσεις Μηχανικής Μάθησης",
  //start ups
  menu_startups: "Για StartUps",
  submenu_DigitalConsulting: "Ψηφιακή Συμβουλευτική",
  submenu_DigitalMarketing: "Ψηφιακό Μάρκετινγκ",
  submenu_BusinessStrategy: "Επιχειρηματική Στρατηγική",
  submenu_BrandDevelopment: "Εξέλιξη επωνυμίας επιχείρησης",
  submenu_WebsiteDevelopment: "Ανάπτυξη Ιστοσελίδων",
  submenu_DesignUIUX: "Σχεδιασμός (UI/UX)",
  submenu_CloudSolutions: "Λύσεις Σύννεφου",
  //about us
  menu_about: "Σχετικά με εμάς",
  submenu_WhoAreWe: "Ποιοί είμαστε",
  submenu_WithAiPath: "Με την AiPath",
  submenu_WhyAiPath: "Γιατί την AiPath",
  submenu_Team: "Η ομάδα μας",
  submenu_Careers: "Θέσεις Εργασίας",
  submenu_Contact: "Επικοινωνία",
  //News
  menu_news: "Νέα",
  //Language
  menu_language: "Γλώσσα",
  //Get Offer
  menu_getOffer: "Λάβετε Προσφορά",
};
