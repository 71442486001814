<template>
      <footer class="footer" data-bg-color="#222429" 
      :style="{backgroundImage:'url('+ require('../../assets/img/bg/04.webp') + ')'}">
         <div class="footer-top pt-115 pb-90">
            <div class="container">
               <div class="row">
                     <div class="col-xl-3 col-lg-3 col-md-6">
                        <div class="footer-widget mb-30">
                           <div class="footer-logo mb-30">
                              <router-link to="/">
                                 <img class="logoFooter" src="../../assets/img/logo/LogoPg-smaller.webp" alt="AiPath Company Logo">
                                 <h1 class="logoFooterTitle">AiPath</h1>
                              </router-link>
                           </div>
                           <div class="footer-text mb-20">
                                 <p>Είμαστε ο σύμμαχός σας σε έναν κόσμο που εξελίσσεται γρήγορα, με το μότο μας να είναι: "Μια εταιρεία τεχνολογίας δίπλα  σε κάθε ανάγκη κάθε επιχείρησης."</p>
                           </div>
                           <!-- <div class="footer-subsccribe">
                                 <h5>Subscribe</h5>
                                 <form action="#">
                                    <input type="email" name="EMAIL" placeholder="Enter your email" required="">
                                    <button type="submit"><i class="fal fa-rocket"></i></button>
                                 </form>
                           </div> -->
                        </div>
                     </div>
                     <div class="col-xl-3 col-lg-3  col-md-6">
                        <div class="footer-widget mb-30 pl-20">
                           <h3 class="footer-title">Περιεχομενα</h3>
                           <div class="footer-menu-2">
                                 <ul>
                                    <li><router-link to="/home">Home</router-link></li>
                                    <li><router-link to="/about">About us</router-link></li>
                                    <!-- <li><router-link to="/blog">Blog</router-link></li> -->
                                    <!-- <li><router-link to="/contact">FAQ</router-link></li> -->
                                    <li><router-link to="/contact">Contact us</router-link></li>
                                    <li><router-link to="/blog">News</router-link></li>
                                    <li><router-link to="/careers">Careers</router-link></li>
                                    <li><router-link to="/gdpr">GDPR</router-link></li>
                                    <!-- <li><router-link to="/blog">Caregivers</router-link></li> -->
                                    <!-- <li><router-link to="/portfolio">Gallery</router-link></li> -->
                                 </ul>
                           </div>
                        </div>
                     </div>
                     <div class="col-xl-3 col-lg-3 col-md-6 d-xl-block">
                        <div class="footer-widget mb-30">
                           <h3 class="footer-title">Επικοινωνια</h3>
                           <a style="color:#999b9f" href="tel:+302113331954">Τηλέφωνο : +30 211 3331954</a>
                           <p style="color:#999b9f">Email : info [ @ ] aipath.gr</p>
                           
                        </div>
                     </div>
                     <div class="col-xl-3 col-lg-3 col-md-6 d-md-none d-xl-block">
                        <div class="footer-widget mb-30">
                           <h3 class="footer-title">Αρθρα</h3>
                           <div class="blog-feeds pr-15">
                             
                              <div v-for="(blog,index) in topThreeBlogs" :key="blog.entry_id" class="signle-blog-feeds mb-20">
                                 <div class="blog-feeds-thumb">
                                    <router-link :to="{ name: 'blog-details', params: { id: blog.entry_id } }">
                                       <img :src="require(`@/assets/img/blog/footer-blog-${index+1}.webp`)" alt="Blog News Cover Image">
                                    </router-link>
                                 </div>
                                 <div class="blog-feeds-text">
                                    <h5><router-link :to="{ name: 'blog-details', params: { id: blog.entry_id } }" v-html="blog.post_title"></router-link></h5>
                                    <span class="feeds-date">{{ blog.date }}</span>
                                 </div>
                              </div>
      
                           </div>
                        </div>
                     </div>
                     
               </div>
            </div>
         </div>
         <div class="copyright-area pt-25 pb-20">
            <div class="container">
               <div class="row">
                     <div class="col-md-6">
                        <div class="footer-copyright">
                           <p class="white-color">Copyright ©{{ currentYear }} AiPath. All Rights Reserved</p>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="footer-social text-start text-md-end">
                           <!-- <a href="#"><i class="fab fa-facebook-f"></i></a>
                           <a href="#"><i class="fab fa-youtube"></i></a>
                           <a href="#"><i class="fab fa-linkedin"></i></a> -->
                           <!-- <a href="#"><i class="fab fa-twitter"></i></a>
                           <a href="#"><i class="fab fa-instagram"></i></a> -->
                        </div>
                     </div>
               </div>
            </div>
         </div>

           <vue-easy-lightbox
            :visible="visible"
            :imgs="galleryData.map(img => img.img)"
            :index="index"
            @hide="handleHide"
        ></vue-easy-lightbox>
      </footer>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import { mapGetters } from "vuex";

export default {
   name:'HomeFooter',
    components: {
     VueEasyLightbox,
    },
    computed: {
    ...mapGetters(['getThreeBlogs']), // Map the getter to a computed property
    topThreeBlogs() {
      return this.getThreeBlogs; // Access the getter through the computed property
    },
  },
   data () {
      return {
         currentYear: new Date().getFullYear(),
         visible: false,
         index: 0,
         galleryData:[
            {
               id:'1',
               img:require(`@/assets/img/instagram/ins1.jpg`)
            },
            {
               id:'2',
               img:require(`@/assets/img/instagram/ins2.jpg`)
            },
            {
               id:'3',
               img:require(`@/assets/img/instagram/ins3.jpg`)
            },
            {
               id:'4',
               img:require(`@/assets/img/instagram/ins4.jpg`)
            },
            {
               id:'5',
               img:require(`@/assets/img/instagram/ins5.jpg`)
            },
            {
               id:'6',
               img:require(`@/assets/img/instagram/ins6.jpg`)
            },
         ]
      }
   },
   methods: {
      showImg(index) {
        this.index = index
        this.visible = true
      },
      handleHide() {
        this.visible = false
      }
    }
}
</script>

<style scoped>
   .logoFooter{
      width:70px;
   }
   .logoFooterTitle{ 
      color: #fff;
      border-bottom: 1px solid;
      margin-top: 1rem;
      text-transform: none;
   }
   .signle-blog-feeds:hover img{
      -webkit-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
   }
   
</style>